import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronRight } from "../../../assets/svg/chevron-outline.svg";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { ButtonsContainer } from "../style";
import {
  ConfirmUnderstandingContainer,
  TextContent,
  BackButton,
} from "./style";

interface IConfirmUnderstandingModalProps {
  onConfirm: () => void;
  handleBack: () => void;
}
function ConfirmUnderstandingModal({
  onConfirm,
  handleBack,
}: IConfirmUnderstandingModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.TransferModal.ConfirmUnderstandingModal",
  });
  return (
    <div>
      <ConfirmUnderstandingContainer>
        {t("understand_warning")}
      </ConfirmUnderstandingContainer>
      <ButtonsContainer>
        <BackButton buttonTheme={ButtonThemeEnum.NEUTRAL2} onClick={handleBack}>
          <ChevronRight />
        </BackButton>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={onConfirm}
          style={{ flex: 1 }}
        >
          {t("confirm")}
        </Button>
      </ButtonsContainer>
    </div>
  );
}

export default ConfirmUnderstandingModal;
