import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { BaseModal } from "../BaseModal";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { Body } from "../../constants/design/fontSize";
import { FlexWrapper, SPACING } from "../../constants/design/spacing";
import { TEXT_COLORS } from "../../constants/design/colors";
import { BulletList } from "./style";

interface IPreLaunchWarningModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

function PreLaunchWarningModal({
  isOpen,
  onConfirm,
  onClose,
}: IPreLaunchWarningModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "PreLaunchWarningModal",
  });

  const handleConfirm = useCallback(() => {
    localStorage.setItem(
      LocalStorageKeyEnum.PRE_LAUNCH_MODAL_ACKNOWLEDGED,
      "true"
    );
    onConfirm();
  }, [onConfirm]);

  const handleClose = useCallback(() => {
    localStorage.setItem(
      LocalStorageKeyEnum.PRE_LAUNCH_MODAL_ACKNOWLEDGED,
      "true"
    );
    onClose();
  }, [onClose]);

  // Helper function to render bullet points
  const renderBulletPoints = useCallback(
    (translate: (key: string) => string) => {
      const bulletPoints = [
        "no_market_orders",
        "special_fees",
        "initial_margin_rate",
        "maintenance_margin_rate",
        "no_index_price",
        "no_funding_payments",
      ];

      return (
        <BulletList>
          {bulletPoints.map((key) => (
            <li key={key}>
              <Body number="three" color={TEXT_COLORS.two}>
                {translate(key)}
              </Body>
            </li>
          ))}
        </BulletList>
      );
    },
    [t]
  );

  return (
    <BaseModal
      show={isOpen}
      onHide={handleClose}
      title={t("title")}
      hideCloseButton={false}
      style={{ width: 310 }}
    >
      <FlexWrapper flexColumn gap={SPACING.four} alignItems="center">
        <Body number="three" color={TEXT_COLORS.two}>
          {t("description")}
        </Body>
        {renderBulletPoints(t)}
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={handleConfirm}
          fullWidth
          style={{ flex: 1 }}
        >
          {t("confirm")}
        </Button>
      </FlexWrapper>
    </BaseModal>
  );
}

export default PreLaunchWarningModal;
