import {
  AllAltChainIdEnum,
  AllChainIdEnum,
  ChainIdEnum,
} from "../../../enums/chain";
import {
  isDevelopment,
  isProduction,
  isProductionTest,
  isStaging,
} from "../../env";
import { AssetResponse } from "../../asset";

export const supportedChainIds = [
  ...AllChainIdEnum.filter(
    (v) => v !== ChainIdEnum.NONE && v !== ChainIdEnum.LINEA_MAINNET
  ),
  ...AllAltChainIdEnum,
];

const getSupportedChain = () => {
  if (isStaging()) {
    return ChainIdEnum.SEPOLIA_TESTNET;
  }

  if (isDevelopment()) {
    return ChainIdEnum.LOCAL_TESTNET;
  }

  return ChainIdEnum.ETH_MAINNET;
};

const getSupportedDepositChains = () => {
  const chains = [getSupportedChain()];
  if (isStaging()) {
    chains.push(ChainIdEnum.OPTIMISM_TESTNET, ChainIdEnum.ARBITRUM_TESTNET);
  } else if (isProduction() || isProductionTest()) {
    chains.push(ChainIdEnum.OPTIMISM, ChainIdEnum.ARBITRUM, ChainIdEnum.BASE);
  } else {
    chains.push(ChainIdEnum.ARBITRUM_LOCAL);
  }
  return chains;
};

export const supportedChainId: ChainIdEnum = getSupportedChain();

export const lineaChainId: ChainIdEnum = ChainIdEnum.LINEA_MAINNET;

export const arbitrumChainId: ChainIdEnum = ChainIdEnum.ARBITRUM;

export const baseChainId: ChainIdEnum = ChainIdEnum.BASE;

export const airdropChainId = (asset: AssetResponse) => {
  switch (asset) {
    case "ZERO":
      return lineaChainId;
    case "LAVA":
      return arbitrumChainId;
    case "BSX":
      return baseChainId;
    default:
      return supportedChainId;
  }
};

export const supportedSigningChainIds: ChainIdEnum[] =
  getSupportedDepositChains();
