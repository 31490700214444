import React, { ReactElement, useContext } from "react";
import { StaticJsonRpcProvider } from "@ethersproject/providers";

export type Web3ContextData = {
  provider: StaticJsonRpcProvider;
  lineaProvider: StaticJsonRpcProvider;
  arbitrumProvider: StaticJsonRpcProvider;
  baseProvider: StaticJsonRpcProvider;
};

interface IWeb3ContextProviderProps {
  children: ReactElement;
}

const defaultProvider = new StaticJsonRpcProvider(
  process.env.REACT_APP_MAINNET_URI ||
    "https://eth-mainnet.alchemyapi.io/v2/non1-7_GRkkf2xkPVP7muaE7YWqETISE"
);

const lineaDefaultProvider = new StaticJsonRpcProvider(
  "https://rpc.linea.build"
);

const arbitrumDefaultProvider = new StaticJsonRpcProvider(
  "https://arb1.arbitrum.io/rpc"
);

const baseDefaultProvider = new StaticJsonRpcProvider(
  "https://mainnet.base.org"
);

export const Web3Context = React.createContext<Web3ContextData>({
  provider: defaultProvider,
  lineaProvider: lineaDefaultProvider,
  arbitrumProvider: arbitrumDefaultProvider,
  baseProvider: baseDefaultProvider,
});

export const useWeb3Context = () => useContext(Web3Context);

export function Web3ContextProvider({ children }: IWeb3ContextProviderProps) {
  return (
    <Web3Context.Provider
      value={{
        provider: defaultProvider,
        lineaProvider: lineaDefaultProvider,
        arbitrumProvider: arbitrumDefaultProvider,
        baseProvider: baseDefaultProvider,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}
