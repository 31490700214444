export interface ITransferData {
  address: string;
  amount: string;
}

export enum TransferStateEnum {
  INITIAL = "INITIAL",
  ADDRESS_CONFIRMATION = "ADDRESS_CONFIRMATION",
  CONFIRMATION = "CONFIRMATION",
  PENDING_SIGNATURE = "PENDING_SIGNATURE",
  SUBMITTING = "SUBMITTING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  UNDERSTANDING_CONFIRMATION = "UNDERSTANDING_CONFIRMATION",
}
