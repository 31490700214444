export enum LocalStorageKeyEnum {
  AUTH_INFO = "authInfo",
  ACCESS_CODE = "accessCode",
  REFERRAL_CODE = "referralCode",
  CAMPAIGN_NAME = "campaignName",
  OFAC = "terms",
  LAST_CONNECTED_WALLET = "lastConnectedWallet",
  IMPORTED_ACCOUNT_ADDRESS = "importedAccountAddress",
  VOLATILITY_WARNING_BANNER = "volatilityWarningBanner",
  LAST_VIEWED_MARKET = "lastViewedMarket",
  WATCHLIST = "watchlist",
  SHOW_EARN_INTRO = "showSavingsIntro",
  SAVINGS_COLLATERAL_ACCUMULATED_YIELD = "savingsCollateralAccumulatedYield",
  TV_PREFERENCES = "tvPreferences",
  TV_CHART_STATE = "tvChartState",
  PERSISTENT_STATE = "persistentState",
  PWA_PROCESSED = "pwaProcessed",
  HIDE_BLUE_AEVO_HEADER = "hideBlueAevoHeader",
  HIDE_REWARDS_WELCOME = "hideRewardsWelcome",
  AIRDROP_POSTED_ON_X = "airdropPostedOnX",
  CODE_VERIFIER = "code_verifier",
  AIRDROP_INFO = "airdropInfo",
  PRE_LAUNCH_MODAL_ACKNOWLEDGED = "preLaunchModalAcknowledged",
}
