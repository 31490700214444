import styled, { css } from "styled-components";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { ReactComponent as Check } from "../../../assets/svg/checkmark.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import {
  COLORS,
  FIELD_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";

export const ConfirmTransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${SPACING.three}px;
  margin-bottom: ${SPACING.five}px;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span:first-child {
    ${FONT_CSS.label.three}
    color: ${TEXT_COLORS.three};
    margin-bottom: ${SPACING.one}px;
  }
  span:last-child {
    ${FONT_CSS.data.two}
    color: ${TEXT_COLORS.one};
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-right: ${SPACING.one}px;
    }
  }
`;

export const BackButton = styled(Button)`
  svg {
    transform: scaleX(-1);
  }
`;

export const TransferProgressContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  margin: ${SPACING.five}px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const svgStyle = css`
  position: absolute;
  width: 32px;
  height: 32px;
`;

export const TransferArrow = styled(ArrowDown)`
  ${svgStyle}
`;

export const TransferSuccessIcon = styled(Check)`
  ${svgStyle}
`;

export const TransferFailedIcon = styled(Close)`
  ${svgStyle}
  stroke: ${COLORS.negative.one};
`;

export const AddressConfirmationContainer = styled.div`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};
  margin-bottom: ${SPACING.three}px;

  > div {
    margin-top: ${SPACING.three}px;
    padding: ${SPACING.two}px;
    background-color: ${FIELD_COLORS.one};
    border-radius: ${BORDER_RADIUS.five}px;

    p {
      ${FONT_CSS.data.three}
      color: ${TEXT_COLORS.one};
      word-break: break-all;
      margin: 0;
    }
  }
`;

export const ConfirmUnderstandingContainer = styled.div`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};
  margin-bottom: ${SPACING.three}px;

  > div {
    margin-top: ${SPACING.three}px;
    padding: ${SPACING.two}px;
    background-color: ${FIELD_COLORS.one};
    border-radius: ${BORDER_RADIUS.five}px;

    p {
      ${FONT_CSS.data.three}
      color: ${TEXT_COLORS.one};
      word-break: break-all;
      margin: 0;
    }
  }
`;
